import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    images: Array,
  };

  static targets = ["background", "foreground"];

  connect() {
    this.currentIndex = 0; // Índice de imagen actual
    this.updateImage(); // Configura la primera imagen
    this.startSlideshow(); // Inicia el cambio de imágenes
  }

  startSlideshow() {
    this.interval = setInterval(() => this.nextImage(), 2000); // Cambia cada 2 segundos
  }

  nextImage() {
    this.currentIndex = (this.currentIndex + 1) % this.imagesValue.length; // Siguiente imagen
    this.updateImage();
  }

updateImage() {
  const nextImageUrl = this.imagesValue[this.currentIndex];
  console.log("Cambiando a la imagen:", nextImageUrl); // Verifica que la URL sea correcta

  // Verifica si los targets están correctamente asignados
  if (this.hasBackgroundTarget) {
    console.log("Actualizando fondo con la imagen:", nextImageUrl);
    this.backgroundTarget.src = nextImageUrl;
  } else {
    console.error("No se encontró el target de fondo (background).");
  }

  if (this.hasForegroundTarget) {
    console.log("Actualizando primer plano con la imagen:", nextImageUrl);
    this.foregroundTarget.src = nextImageUrl;
  } else {
    console.error("No se encontró el target de primer plano (foreground).");
  }
}


  disconnect() {
    clearInterval(this.interval); // Detiene el intervalo al desconectarse
  }
}
