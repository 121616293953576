import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    playlist: Array,
  };

  static targets = ["audio", "icon"];

  connect() {
    if (this.playlistValue.length === 0) {
      console.error("Playlist está vacío");
      return;
    }

    this.currentIndex = 0;
    this.loadAudio(this.currentIndex);

    // Eventos del reproductor
    this.audioTarget.addEventListener("ended", this.next.bind(this));
    this.audioTarget.addEventListener("error", this.handleAudioError.bind(this));

    console.log("PlayerPlaylistController conectado");
  }

  toggle() {
    if (this.audioTarget.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  play() {
    this.audioTarget.play();
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove("fa-play");
      this.iconTarget.classList.add("fa-pause");
    }
  }

  pause() {
    this.audioTarget.pause();
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove("fa-pause");
      this.iconTarget.classList.add("fa-play");
    }
  }

  next() {
    this.currentIndex = (this.currentIndex + 1) % this.playlistValue.length;
    this.loadAudio(this.currentIndex);
    this.play();
  }

  loadAudio(index) {
    if (!this.playlistValue[index]) {
      console.error(`No se encontró el archivo en el índice ${index}`);
      return;
    }

    this.audioTarget.src = this.playlistValue[index];
    console.log(`Cargando audio: ${this.audioTarget.src}`);
  }

  handleAudioError() {
    console.error("Error al reproducir el audio:", this.audioTarget.src);
    this.next();
  }
}
